<template>
  <div>
    <div class="home" :style="`background-image:url(${shopDetail.logo})`"></div>
    <div class="title">{{ shopDetail.merchantName }}</div>
    <div class="goto" @click="gotoOrder">去点餐</div>
    <van-row gutter="10" style="margin: 1rem">
      <van-col span="8">
        <div class="into">
          <div style="margin-bottom: 1rem">会员充值</div>
          <div>
            <van-icon name="cash-back-record" color="#ff6034" size="2.5rem" />
          </div>
        </div>
      </van-col>
      <van-col span="8">
        <div class="into">
          <div style="margin-bottom: 1rem">积分兑换</div>
          <div>
            <van-icon name="point-gift-o" color="#ff6034" size="2.5rem" />
          </div>
        </div>
      </van-col>
      <van-col span="8">
        <div class="into">
          <div style="margin-bottom: 1rem">领券中心</div>
          <div>
            <van-icon name="cash-on-deliver" color="#ff6034" size="2.5rem" />
          </div>
        </div>
      </van-col>
    </van-row>
    <div class="member">
      <van-row align="center" type="flex">
        <van-col span="4">
          <van-icon name="flower-o" color="#ff6034" size="2rem" />
        </van-col>
        <van-col span="16" style="text-align: left">
          <div style="font-size: 1rem; margin-bottom: 0.2rem; color: #000">
            会员专享
          </div>
          <div style="font-size: 0.6rem">
            我的可用余额:{{userDetail.usableScore == null ? 0:userDetail.usableScore}}，我的可用积分:{{userDetail.remaining ==null?0:userDetail.remaining}}
          </div>
        </van-col>
        <van-col span="4">
          <van-icon name="friends" color="#ff6034" size="2rem" />
        </van-col>
      </van-row>
    </div>
    <div class="contact">
      <van-row type="flex" justify="space-between" gutter="20" style="margin-bottom: 0.5rem">
        <van-col span="12" style="text-align: left">
          <van-icon name="phone-o" color="#333" size="0.8rem" />联系电话：
        </van-col>
        <van-col span="12" style="text-align: right">{{shopDetail.linkPhone}}</van-col>
      </van-row>
      <van-row type="flex" justify="space-between" gutter="20" style="margin-bottom: 0.5rem">
        <van-col span="12" style="text-align: left">
          <van-icon name="clock-o" color="#333" size="0.8rem" />营业时间：
        </van-col>
        <van-col span="12" style="text-align: right">{{shopDetail.openingTime}}</van-col>
      </van-row>
      <van-row type="flex" justify="space-between" gutter="20" style="margin-bottom: 0.5rem">
        <van-col span="12" style="text-align: left">
          <van-icon name="bar-chart-o" color="#333" size="0.8rem" />WIFI名称：
        </van-col>
        <van-col span="12" style="text-align: right">地锅鸡</van-col>
      </van-row>
      <van-row type="flex" justify="space-between" gutter="20">
        <van-col span="12" style="text-align: left">
          <van-icon name="eye-o" color="#333" size="0.8rem" />WIFI密码：
        </van-col>
        <van-col span="12" style="text-align: right">88888888</van-col>
      </van-row>
    </div>
    <!-- 地图 -->
    <div class="map">
      <div>
        <van-icon name="location-o" color="#333" size="0.8rem" />{{shopDetail.address}}
      </div>
      <div id="container" style="height: 12rem; margin-top: 1rem"></div>
    </div>
  </div>
</template>

<script>
import bg from "../assets/logo.jpg";
import AMapLoader from '@amap/amap-jsapi-loader';
import { mapState } from "vuex";
export default {
  name: "Home",
  data () {
    return {
      bg: bg,
      map: null,
      shopDetail: {},
      userDetail: {},
    };
  },
  computed: {
    ...mapState(["memberCode"]),
  },
  mounted () {
    this.getIndexList()
    this.getUserList()
  },
  methods: {
    gotoOrder () {
      this.$router.push({ path: "/order" });
    },
    // 获取商家信息
    getIndexList () {
      this.$http
        .post("merchantBaseInfo/queryOne", {
          merchantCode: "988780",
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.shopDetail = res.data.data;
            this.loadMap();
          }
        });
    },
    getUserList () {
      this.$http
        .post("merchantMember/queryMemberInfo", {
          merchantCode: "10000098",
          phone: '18855953550'
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.userDetail = res.data.data;
          }
        });

    },
    loadMap () {
      AMapLoader.load({
        "key": "fd81a4776ad400353e6239e98718eb23",  // 申请好的Web端开发者Key
        "version": "1.4.15",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      }).then((AMap) => {
        this.map = new AMap.Map('container', {
          center: [this.shopDetail.longitude, this.shopDetail.latitude],
          resizeEnable: true,
          zoom: 15
        });
        // 创建一个 Marker 实例：
        var marker = new AMap.Marker({
          position: new AMap.LngLat(this.shopDetail.longitude, this.shopDetail.latitude),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          title: '北京',
        });
        // 将创建的点标记添加到已有的地图实例：
        this.map.add(marker);

      }).catch(e => {
        console.log(e);
      })

    }
  },
};
</script>
<style scoped>
.title {
  position: absolute;
  z-index: 1000;
  top: 1rem;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
  width: 100%;
}
.home {
  height: 15rem;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 1rem;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
  position: relative;
}
.member {
  background: #fff;
  padding: 1.5rem 0;
  border-radius: 0.5rem;
  box-shadow: 2px 2px 5px #eee;
  margin: 1rem;
  color: #ccc;
}

.into {
  background: #fff;
  padding: 1rem 0;
  border-radius: 0.5rem;
  box-shadow: 2px 2px 5px #eee;
  font-size: 1rem;
}
.goto {
  position: relative;
  z-index: 1000;
  margin: 0 1rem;
  margin-top: -2rem;
  background: #ff6034;
  padding: 1rem 0;
  border-radius: 0.4rem;
  font-size: 1rem;
  color: #fff;
  box-shadow: 3px 3px 10px #f8c8ba;
}
.contact {
  background: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 2px 2px 5px #eee;
  margin: 1rem;
  color: #333;
  font-size: 0.8rem;
}
.map {
  background: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 2px 2px 5px #eee;
  margin: 1rem;
  color: #333;
  font-size: 0.8rem;
  text-align: left;
}
</style>