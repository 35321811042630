import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    memberCode: sessionStorage.getItem('memberCode') || ''
  },
  mutations: {
    updateMemberCode (state, code) {
      sessionStorage.setItem('memberCode', code)
      state.memberCode = code
    }
  },
  actions: {
  },
  modules: {
  }
})
