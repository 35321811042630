import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './com/com-css.css'



import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant)
import axios from 'axios'
axios.defaults.baseURL = 'https://train.yunlankeji.com/api/'
Vue.prototype.$http = axios

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
